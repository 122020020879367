import queryString from 'query-string'
import { AREA } from '../constants'

export const isDefaultArea = () => {
  if (typeof window === 'undefined' || typeof document === 'undefined')
    return true

  const search = queryString.parse(window.location.search)
  return !search?.area || search.area === AREA
}
