export const screens = {
  screenMobilePopup: 590,
  screenSMax: 767,
  screenMMin: 768,
  screenMMax: 1023,
  screenLMin: 1024,
  screenLMax: 1279,
  screenXLMin: 1280,
}

export const screenSizes = {
  iphone5: { query: '(max-width: 320px)' },
  mobile: { query: '(max-width: 479px)' },
  mobileMenu: { query: '(max-width: 1023px)' },
  smallTablet: { query: '(min-width: 480px) and (max-width: 767px)' },
  minTablet: { query: '(min-width: 768px)' },
  maxTablet: { query: '(max-width: 1023px)' },
  tablet: { query: '(min-width: 768px) and (max-width: 1023px)' },
  bigTablet: { query: '(min-width: 1024px) and (max-width: 1279px)' },
  maxBigTablet: { query: '(max-width: 1279px)' },
  desktop: { query: '(min-width: 1280px) and (max-width: 1439px)' },
  largeDesktop: { query: '(min-width: 1440px)' },
}

export const AREA = 'ic'

export const AREA_EMAIL = 'feedback@ipoteka.center'

export const REDIRECT_URL = process.env.GATSBY_REDIRECT_API_URL

export const BASIS_PHONE = {
  phoneHref: '+78003337256',
  phoneNumber: '8 800 333 72 56',
}

export const YA_COUNTER = '85790126'

export const USER_AGREEMENT_LINK = 'https://legal.ipoteka.center/user-agreement'

export const PRIVACY_POLICY_LINK = 'https://legal.ipoteka.center/privacy-policy'

export const PERSON_DATA_AGREEMENT_LINK =
  'https://legal.ipoteka.center/person-data-agreement'

export const DISTRIBUTION_AGREEMENT_LINK =
  'https://legal.ipoteka.center/distribution-agreement'

export const STRAPI_API_URL =
  process.env.GATSBY_STRAPI_API_URL || process.env.STRAPI_API_URL
