const { isDefaultArea } = require('./src/helpers/area')

window.amo_social_button = {
  id: '36821',
  hash: 'eb692e48ba2eacbd4379a1f906e53438e6c3c76272fe238c8a0a6c2f460a2985',
  locale: 'ru',
  setMeta: function (params) {
    this.params = this.params || []
    this.params.push(params)
  },
}

exports.onInitialClientRender = () => {
  if (!isDefaultArea()) return
  var script = document.createElement('script')
  script.setAttribute('src', 'https://gso.amocrm.ru/js/button.js?1612277937')
  script.setAttribute('type', 'text/javascript')
  script.setAttribute('charset', 'utf8')
  script.setAttribute('async', '')
  script.setAttribute('id', 'amo_social_button_script')
  document.head.appendChild(script)
}

exports.onServiceWorkerUpdateReady = () => window.location.reload()
